import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { base, baseSepolia, mainnet } from "viem/chains";
import { WagmiProvider } from "wagmi";
import type { ChainAddresses } from "./types";

export const maxImageSize = 1024 * 1024 * 20; // 10MB
export const maxStickerUploadSize = 1024 * 1024 * 5; // 5MB
export const maxVideoSize = 1024 * 1024 * 100; // 100MB

export const defaultCurrency = "WETH";

const env = (process.env.NODE_ENV as string) ?? "staging";

interface Features {
  buyStickers: boolean;
  enableStickerCreation: boolean;
  enableCreateStickerFromUploads: boolean;
  enableAirdropModal: boolean;
  enableClaimAirdrop: boolean;
  defaultChain: number;
  enableStreamerApplications: boolean;
  enableGuilds: boolean;
  enableFollowGame: boolean;
  enableTwitchLiveStreams: boolean;
  notifyCommunityAdminUsers: boolean;
  notifyStreamersNewBounty: boolean;
  enableMenuLinksRemix: boolean;
}

const baseFeatures: Features = {
  buyStickers: true,
  enableStickerCreation: true,
  enableCreateStickerFromUploads: true,
  enableAirdropModal: true,
  enableClaimAirdrop: true,
  defaultChain: 84532,
  enableStreamerApplications: true,
  enableGuilds: true,
  enableFollowGame: true,
  enableTwitchLiveStreams: true,
  notifyCommunityAdminUsers: true,
  notifyStreamersNewBounty: true,
  enableMenuLinksRemix: true,
};

export const features: { [key: string]: Features } = {
  production: {
    ...baseFeatures,
    enableCreateStickerFromUploads: false,
    enableAirdropModal: false,
    enableClaimAirdrop: false,
    defaultChain: 8453,
    enableGuilds: true,
    enableFollowGame: true,
    enableTwitchLiveStreams: true,
    notifyCommunityAdminUsers: true,
    notifyStreamersNewBounty: true,
    enableMenuLinksRemix: false,
  },
  staging: { ...baseFeatures },
  development: { ...baseFeatures },
  test: { ...baseFeatures },
}[env];

export const appLandingPath = "/lobby";
export const signInMessage = "Sign in to Soulbound";
export const addWalletMessage = "Add your wallet to Soulbound";
const walletConnectProjectId = "ef5cb9664a562c0a7ec3a0e8f706e5d1";

export const rainbowKitConfig = getDefaultConfig({
  appName: "Soulbound",
  projectId: walletConnectProjectId,
  chains: [mainnet, base, baseSepolia],
  ssr: true,
});

export { WagmiProvider };

export const referralDripAmount = 200;
export const defaultStickerPrice = 0.001;
export const defaultBetAmount = 10;
export const purchaseConfirmationCount = 4;
export const soulboundConversionFactor = 0.017;
export const dailyPointEarnedLimit: { [key: string]: number } = {
  "daily/post_slapped": 400,
  "daily/slapped_sticker": 400,
  "daily/referral": referralDripAmount * 50,
};
export const chainAddresses: ChainAddresses = {
  8453: {
    soulboundAccountAddress: "0x2B1116183740C1C92e7B09e01dbBB5DC7dda4146",
    popTokenAddress: "0x4200000000000000000000000000000000000006",
    airdropDistributorAddress: "0x190e0B134330858372aeeFba38FD84B9eD9C3221",
    buyLink: "https://kyberswap.com/swap/base/eth-to-weth",
    sellLink: "https://kyberswap.com/swap/base/weth-to-eth",
  },
  84532: {
    soulboundAccountAddress: "0x00401F9b1A9A84bF79218fB1689245821c2b91A7",
    popTokenAddress: "0x3a2a541846dE64fb12df85fF59900fAeB07CE8CE",
    airdropDistributorAddress: "0xF51BFB0790B1fb4254b1Ea94f92aD9fFa3928286",
    buyLink: "https://kyberswap.com/swap/base/eth-to-weth",
    sellLink: "https://kyberswap.com/swap/base/weth-to-eth",
  },
};

export const alchemyUrls = [
  "https://eth-mainnet.g.alchemy.com/v2/9RWaYuMtZ2jcIjRBwBWkUzhBa7zKKwn4",
  "https://base-mainnet.g.alchemy.com/v2/e6R3PpI1eZHhrRpvtD54WKEWcN1iR6S5",
];

export const subscribeEmailBeehiveLink = [
  "https://magic.beehiiv.com/v1/fc54ee82-8681-43ef-85f6-bff7aab615f3",
  "https://embeds.beehiiv.com/d0a3e0c1-675a-4f2c-aec6-0fad8446bc41",
];

export const DEFAULT_AVATAR_PATH = "/default-avatar.svg";
